<template>
<div data-html2canvas-ignore class="relative" style="z-index: 9999;" aria-labelledby="modal-title" role="dialog" aria-modal="true">

    <div class="fixed inset-0 bg-gray-700 bg-opacity-40 transition-opacity animate__animated animate__fadeIn" style="--animate-duration: 0.4s"></div>

    <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

            <section class="shadow-2xl bg-white max-w-2xl rounded-md min-w-[600px] animate__animated animate__zoomIn" style="--animate-duration: 0.2s">

                <div class="p-8 text-left sm:p-12">

                    <component :is="mainStore.currentSupportView"></component>

                </div>
            </section>

        </div>

    </div>

</div>
</template>

<script>
import {
    mapStores,
    mapActions
} from "pinia";
import useMainStore from "@/stores/main";
import TicketForm from "./TicketForm.vue";
import TopIssues from "./TopIssues.vue";

export default {
    components: {
        TicketForm,
        TopIssues
    },
    data() {
        return {
            subject: '',
            description: '',
            schema: {
                subject: "required|min:10",
                description: "required|min:10",
                role: "required",
                course: "required",
            },
            isSubmittingTicket: false,
            isGeneratingScreenShot: false,
            showScreenShot: false,
            refrepsTicketID: '',
            hasImage: false,
            file: null,
            issueType: ''
        }
    },
    computed: {
        ...mapStores(useMainStore),
    },
    methods: {
        generateString() {
            const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789';
            let result = '';

            for (let i = 0; i < 10; i++) {
                const randomIndex = Math.floor(Math.random() * chars.length);
                result += chars[randomIndex];
            }

            return result;
        }
    },
    mounted() {
        
        this.refrepsTicketID = this.generateString();

        // if (this.mainStore.role == 'instructor') {
            this.axios.post(`${import.meta.env.VITE_BASE_LINK}/get-students-for-ticket`, {
                companyID: this.mainStore.organization ?? 'NA',
            })
            .then(response => {
                this.mainStore.orgStudents = response.data.students
                console.log(response.data.students)
            })
            .catch(error => {
                console.log(error)
                this.mainStore.orgStudents = []
            })
        // }
        
    }
}
</script>

<style>
.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.2s linear;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
}

.slide-fade-leave-active {
    transition: all 0.2s linear;
}

.pop-enter-active,
.pop-leave-active {
    transition: transform 0.4s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.4s linear;
}

.pop-enter,
.pop-leave-to {
    opacity: 0;
    transform: scale(0.3) translateY(-50%);
}
</style>
