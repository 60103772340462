<template>
<!-- Global notification live region, render this permanently at the end of the document -->
<div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 mt-14 animate__animated animate__slideInRight">
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
       
        <div class="pointer-events-auto flex w-full max-w-md rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div class="w-0 flex-1 p-4">
                <div class="flex items-start">
                    <div class="flex-shrink-0 pt-0.5">
                        <img class="h-10 w-10 rounded-full" :src="mainStore.inComingMessage.profileImage ?? 'https://d3sjyulja6yeep.cloudfront.net/profiles/defaultv2.jpg'" alt="">
                    </div>
                    <div class="ml-3 w-0 flex-1">
                        <p class="text-sm font-medium text-gray-900">{{ mainStore.inComingMessage.senderName }}</p>
                        <p class="text-sm text-gray-500">{{ mainStore.inComingMessage.message }}</p>
                    </div>

                </div>
                <div v-if="shouldReply" class="ml-3 w-full flex-1 mt-2">
                    <input v-model="text" type="text" class="w-full border border-gray-200 rounded-md shadow-sm px-4 py-2 mt-2 mr-4 focus:outline-none focus:ring-1 focus:ring-gray-600 focus:border-gray-600 sm:text-sm" placeholder="Type a message...">
                    <span class="isolate inline-flex w-full justify-end mt-1.5">
                        <button @click="mainStore.showNewChatMessage = false" type="button" class="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:z-10">Cancel</button>
                        <button @click="sendChat()" type="button" class="relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:z-10">Send Message</button>
                    </span>

                </div>
            </div>

            <di v-if="!shouldReply" class="flex border-l border-gray-200">
                <button @click="shouldReply = true" type="button" class="flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent p-4 text-sm font-medium text-teal-400 hover:text-teal-500">Reply</button>
            </di>
        </div>
    </div>
</div>
</template>

<script>

import {
    mapStores
} from "pinia";
import useMainStore from "@/stores/main";

export default {
    name: "NewChatMessageNotification",
    data() {
        return {
            shouldReply: false,
            text: ''
        }
    },
    methods: {
        sendChat() {
            if (this.text == '') return
            this.axios.post(`${import.meta.env.VITE_BASE_LINK}/send-chat`, {
                id: this.mainStore.inComingMessage.message_id,
                text: this.text,
                recipient_id: this.mainStore.inComingMessage.senderID,
                senderName: this.mainStore.user.name,
                senderID: this.mainStore.user.id,
            }).then((response) => {

                this.mainStore.socket.emit("new-chat-message", {
                    id: this.mainStore.inComingMessage.message_id,
                    recipient_id: this.mainStore.inComingMessage.senderID,
                    messages: response.data.chat.messages,
                    senderName: this.mainStore.user.name,
                    senderID: this.mainStore.user.id,
                    profileImage: this.mainStore.user.profileImage,
                    message: this.text,
                    message_id: this.mainStore.inComingMessage.message_id
                });
                setTimeout(() => {
                    this.mainStore.showNewChatMessage = false
                    this.text = ''
                }, 200)
           
                
            }).catch((error) => {
                console.log(error)
            })
        },
    },
    computed: {
    ...mapStores(useMainStore),
  },

    mounted() {

    }
}
</script>
