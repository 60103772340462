<template>
<div data-html2canvas-ignore class="relative" style="z-index: 9999;" aria-labelledby="modal-title" role="dialog" aria-modal="true">

    <div class="fixed inset-0 bg-gray-700 bg-opacity-40 transition-opacity animate__animated animate__fadeIn" style="--animate-duration: 0.4s"></div>

    <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

            <section class="shadow-2xl bg-white max-w-2xl rounded-md min-w-[600px] animate__animated animate__zoomIn" style="--animate-duration: 0.2s">

                <div class="p-8 text-left sm:p-12">

                    <div class="py-2 animate__animated animate__fadeInLeft">

                        <h2 class="text-[22px] font-bold text-black">
                            <font-awesome-icon icon="fa-regular fa-message-lines" class="h-5 w-5 mr-2" />

                            <span>Compose New Message</span>
                        </h2>
                        <p class="text-[16px] font-regular text-gray-500 mb-4">You can send a message to all of your students or to a specific class.</p>

                        <vee-form :validation-schema="schema" @submit="submitTicket" class="mt-1 max-w-4xl mx-auto">
                            <div class="grid grid-cols-1 gap-6">

                                <div class="grid grid-cols-1 gap-4">

                                    <label class="block">
                                        <span class="text-black font-bold">Select Audience</span>
                                        <vee-field v-model="audience" as="select" name="course" class="block w-full mt-1">
                                            <optgroup label="Courses">
                                                <option v-for="course in mainStore.courses.filter(course => course.type == 'course')" :value="course.name">{{ course.name }} Learners</option>
                                            </optgroup>
                                            <optgroup label="Students">
                                                <option value="All Students">All Learners</option>
                                                <option v-for="student in mainStore.students" :value="student.info.name">{{ student.info.name }}</option>
                                            </optgroup>
                                        </vee-field>
                                        <ErrorMessage class="text-red-600" name="course" />
                                    </label>

                                    <label class="block">
                                        <span class="text-black font-bold">Message Subject</span>
                                        <vee-field as="input" name="subject" class="mt-1 block w-full" placeholder="Enter message subject here..." />
                                        <ErrorMessage class="text-red-600" name="course" />
                                    </label>

                                </div>

                                <label class="block">
                                    <span class="text-black font-bold">Message Title</span>
                                    <br>
                                    <vee-field as="textarea" v-model="description" name="message" id="message" class="mt-1 block w-full h-48" placeholder="Enter message here..." />
                                    <p class="mt-2" :class="!descriptionIsValid ? 'text-gray-800' : 'text-green-600'">
                                        <font-awesome-icon v-if="descriptionIsValid" icon="fa-solid fa-check" class="mr-2" />{{ !descriptionIsValid ? `${15 - description.split('').length} characters remaining to be valid.` : 'Description minimum met!' }}</p>
                                    <ErrorMessage class="text-red-600" name="message" />
                                </label>

                            </div>
                            <div class="flex items-center justify-center w-full">
                                <button class="mt-8 inline-flex items-center justify-center w-full bg-black py-4 text-sm text-center font-bold text-white shadow-xl">
                                    <span v-if="!isSubmittingMessage" class="text-lg">Send Message</span>
                                    <font-awesome-icon v-if="isSubmittingMessage" icon="fa-duotone fa-spinner-third" class="w-6 h-6 ml-2 text-white" spin />
                                </button>

                            </div>

                        </vee-form>

                    </div>

                </div>
            </section>

        </div>

    </div>

</div>
</template>

    
    
<script>
import {
    mapStores,
    mapActions
} from "pinia";
import useMainStore from "@/stores/main";

export default {
    components: {

    },
    data() {
        return {
            subject: '',
            description: '',
            schema: {
                subject: "required|min:10",
                description: "required|min:10",
                role: "required",
                course: "required",
            },
            isSubmittingMessage: false,
            isGeneratingScreenShot: false,
            showScreenShot: false,
            refrepsTicketID: '',
            hasImage: false,
            file: null,
            issueType: '',
            audience: 'All Courses'
        }
    },
    computed: {
        ...mapStores(useMainStore),
        descriptionIsValid() {
            return this.description.length >= 15;
        },
    },
    methods: {
        
    },
    mounted() {

    }
}
</script>
    
    
<style>
.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.2s linear;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
}

.slide-fade-leave-active {
    transition: all 0.2s linear;
}

.pop-enter-active,
.pop-leave-active {
    transition: transform 0.4s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.4s linear;
}

.pop-enter,
.pop-leave-to {
    opacity: 0;
    transform: scale(0.3) translateY(-50%);
}
</style>
