<template>
<div class="animate__animated animate__fadeInRight">
    <button @click="mainStore.showSubmitTicketModal = false" class="inline-flex w-full items-center justify-end"><font-awesome-icon icon="fa-solid fa-xmark" class="h-6 w-6" /></button>
    <h1 class="text-black text-2xl font-bold">What can we help you with?</h1>
    <p class="mb-6 pt-1 text-gray-400">Don't waste time and see if your question can be answered here!</p>
    <ul role="list" class="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
        <li class="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6">
            <div class="flex min-w-0 gap-x-4">
                <img class="h-8 w-8 flex-none" src="https://d3sjyulja6yeep.cloudfront.net/logos/refreps_logo_web.png" alt="">
                <div class="min-w-0 flex-auto">
                    <p class="text-sm font-semibold leading-6 text-gray-900">
                        <a href="https://support.refreps.com/support/solutions/articles/151000082592-progression-issue" target="_blank">
                            <span class="absolute inset-x-0 -top-px bottom-0"></span>
                            Progression Issue
                        </a>
                    </p>

                </div>
            </div>
            <div class="flex shrink-0 items-center gap-x-4">

                <svg class="h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                </svg>
            </div>
        </li>
        <li class="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6">
            <div class="flex min-w-0 gap-x-4">
                <img class="h-8 w-8 flex-none" src="https://d3sjyulja6yeep.cloudfront.net/logos/refreps_logo_web.png" alt="">
                <div class="min-w-0 flex-auto">
                    <p class="text-sm font-semibold leading-6 text-gray-900">
                        <a href="https://support.refreps.com/support/solutions/articles/151000098610-how-to-clear-cache" target="_blank">
                            <span class="absolute inset-x-0 -top-px bottom-0"></span>
                            How to Clear Cache
                        </a>
                    </p>
                </div>
            </div>
            <div class="flex shrink-0 items-center gap-x-4">

                <svg class="h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                </svg>
            </div>
        </li>
        <li class="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6">
            <div class="flex min-w-0 gap-x-4">
                <img class="h-8 w-8 flex-none" src="https://d3sjyulja6yeep.cloudfront.net/logos/refreps_logo_web.png" alt="">
                <div class="min-w-0 flex-auto">
                    <p class="text-sm font-semibold leading-6 text-gray-900">
                        <a href="https://support.refreps.com/support/solutions/articles/151000082572-how-to-reset-create-your-password" target="_blank">
                            <span class="absolute inset-x-0 -top-px bottom-0"></span>
                            How to Reset/Create your Password
                        </a>
                    </p>

                </div>
            </div>
            <div class="flex shrink-0 items-center gap-x-4">

                <svg class="h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                </svg>
            </div>
        </li>
        <li class="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6">
            <div class="flex min-w-0 gap-x-4">
                <img class="h-8 w-8 flex-none" src="https://d3sjyulja6yeep.cloudfront.net/logos/refreps_logo_web.png" alt="">
                <div class="min-w-0 flex-auto">
                    <p class="text-sm font-semibold leading-6 text-gray-900">
                        <a href="https://support.refreps.com/support/solutions/articles/151000082574-how-to-login" target="_blank">
                            <span class="absolute inset-x-0 -top-px bottom-0"></span>
                            How to Login
                        </a>
                    </p>

                </div>
            </div>
            <div class="flex shrink-0 items-center gap-x-4">

                <svg class="h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                </svg>
            </div>
        </li>
        <li class="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6">
            <div class="flex min-w-0 gap-x-4">
                <img class="h-8 w-8 flex-none" src="https://d3sjyulja6yeep.cloudfront.net/logos/refreps_logo_web.png" alt="">
                <div class="min-w-0 flex-auto">
                    <p class="text-sm font-semibold leading-6 text-gray-900">
                        <a href="https://support.refreps.com/support/solutions" target="_blank">
                            <span class="absolute inset-x-0 -top-px bottom-0"></span>
                            See More Here
                        </a>
                    </p>

                </div>
            </div>
            <div class="flex shrink-0 items-center gap-x-4">

                <svg class="h-5 w-5 flex-none text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                </svg>
            </div>
        </li>

    </ul>

    
    <button @click="goToTicketForm()" class="mt-6 inline-flex items-center justify-center w-full px-6 py-3 mb-2 text-[16px] text-black bg-gray-100 rounded-md hover:bg-gray-300 sm:w-full sm:mb-0">
        Submit Help Ticket
        <svg class="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path>
        </svg>
    </button>

</div>
</template>

<script>
import {
    mapStores,
    mapActions
} from "pinia";
import useMainStore from "@/stores/main";

export default {
    name: "TopIssues",
    computed: {
        ...mapStores(useMainStore),
    },
    methods: {
        goToTicketForm() {
            this.mainStore.currentSupportView = 'TicketForm';
        }
    },
    mounted() {
        console.log('Loaded TopIssues.vue')
    }
}
</script>

<style lang="scss" scoped>

</style>
