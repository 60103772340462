<template>
<header class="bg-white shadow-lg fixed top-0 w-full" style="z-index: 99999" v-if="!mainStore.hideNavBar">
    <nav class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8" aria-label="Top">
        <div class="flex w-full items-center justify-between border-b border-gray-500 py-2.5 lg:border-none">
            <div class="flex items-center">
                <a href="https://www.refreps.com">
                    <span class="sr-only">RefReps</span>
                    <img class="h-8 w-auto" :src="
                showNBA
                  ? 'https://api.refreps.com/images/jrnba_cobrand.png'
                  : 'https://d3sjyulja6yeep.cloudfront.net/logos/refreps_logo_web.png'
              " alt="" />
                </a>
                <div class="ml-10 hidden lg:block">
                    <router-link v-if="mainStore.isLoggedIn" :to="{ name: 'dashboard' }" class="inline-flex items-center mr-5">
                        <font-awesome-icon icon="fa-regular fa-gauge" class="mr-1" />
                        <a class="text-[14px] inline-block font-regular hover:text-teal-900" href="#">My Dashboard</a>
                    </router-link>

                    <router-link :to="{ name: 'explore' }" @click="
                mainStore.isLoggedIn
                  ? (mainStore.hasClickedExploreOnce = true)
                  : (mainStore.hasClickedExploreOnce = false)
              " class="inline-flex items-center font-regular hover:text-gray-900 relative mr-5">
                        <!-- <font-awesome-icon icon="fa-regular fa-store" class="mr-1" /> -->
                        <font-awesome-icon icon="fa-regular fa-compass" class="mr-1" />
                        <span class="text-[14px]">Explore</span>
                    </router-link>

                    <!-- <router-link v-if="mainStore.mode == 'development'" :to="{ name: 'messages' }" class="inline-flex items-center font-regular hover:text-gray-900 relative mr-5">
                        <font-awesome-icon icon="fa-regular fa-messages" class="mr-1" />
                        <span class="text-[14px]">Messages</span>
                    </router-link> -->

                    <!-- <router-link v-if="mainStore.isUserInstructor && mainStore.isLoggedIn" :to="{
                name: 'organization',
                params: { id: mainStore.organization.companyID },
              }" @click="mainStore.isLoadingInstructor = true" class="inline-flex items-center font-regular hover:text-gray-900 relative mr-5">

                        <font-awesome-icon icon="fa-regular fa-screen-users" class="mr-1" />
                        <a class="text-[14px] inline-block font-regular hover:text-teal-900" href="#">
                            Instructor
                        </a>
                    </router-link> -->
                </div>
            </div>

            <div class="ml-10 flex items-center justify-end relative">
                <!-- <div v-tooltip="{ content: `Use toward next purchase`, html: true }" class="relative" v-if="mainStore.isLoggedIn">
                    <span class="flex h-3 w-3 absolute mt-1" v-if="mainStore.user.credit.amount != 0">
                        <span class="animate-ping absolute inline-flex h-2 w-2 rounded-full bg-teal-400 opacity-75"></span>
                        <span class="relative inline-flex rounded-full h-2 w-2 bg-teal-500"></span>
                    </span>
                    <router-link :to="{ name: 'explore' }" class="font-extrabold" :class="mainStore.user.credit.amount > 0 ? 'text-transparent text-lg bg-clip-text bg-gradient-to-r from-teal-400 to-teal-500' : 'text-black'">Credit: ${{ mainStore.user.credit.amount.toFixed(2) }}</router-link>
                </div> -->

                <div v-if="mainStore.isLoggedIn" class="flex justify-center">
                    <div class="relative">
                        <button @click="mainStore.showNotifications = true" class="relative text-gray-600 z-10 block rounded-md bg-white p-2 focus:outline-none">
                            <font-awesome-icon icon="fa-regular fa-bell" class="h-6 w-6" />
                            <div v-if="mainStore.unreadMessages != 0" class="inline-flex absolute text-xs top-1.5 right-1 w-fit px-1 justify-center items-center font-bold text-white bg-red-500 rounded-full">{{ mainStore.unreadMessages }}</div>
                        </button>
                        <div v-show="showNotifications" @click="showNotifications = false" class="fixed inset-0 h-full w-full z-10"></div>
                    </div>
                </div>

                <router-link :to="{ name: 'checkout-cart' }" :class="!mainStore.isLoggedIn ? 'pr-2' : ''" aria-label="show notifications" class="relative hover:text-teal-400 mx-2 h-full flex items-center justify-center text-gray-600 dark:text-gray-200 cursor-pointer">
                    <font-awesome-icon icon="fa-regular fa-cart-shopping" class="h-6 w-6" />

                    <div class="absolute top-0 left-4 mt-[-15px] mr-4 pr-1 pt-1 animate__animated animate__faster animate__bounceIn" v-if="mainStore.activeCartItems.length != 0 && mainStore.isLoggedIn">
                        <span class="rounded-full bg-gray-200 px-1.5 py-0.5 text-xs font-medium text-gray-800">{{ mainStore.activeCartItems.length }}</span>
                    </div>
                </router-link>
                <div class="flex items-center">
                    <div class="h-full flex items-center">
                        <div class="relative inline-block" v-if="mainStore.isLoggedIn"></div>
                        <div class="h-full flex items-center" style="z-index: 99999" v-if="mainStore.isLoggedIn">
                            <div class="relative ml-3" style="z-index: 99999" v-click-away="onClickAway">
                                <div>

                                    <button @click="showProfile = !showProfile" type="button" class="flex rounded-full bg-gray-100 text-sm pr-0.5" id="user-menu-button" aria-expanded="false" aria-haspopup="true">
                                        <span class="sr-only">Open user menu</span>
                                        <img class="h-8 w-8 rounded-full object-cover" :src="mainStore.user.profileImage || 'https://d3sjyulja6yeep.cloudfront.net/profiles/defaultv2.jpg'
                        " alt="" />
                                    </button>
                                </div>

                                <transition name="slide-fade3">
                                    <div v-if="showProfile" style="z-index: 99999" class="px-3 w-[225px] mx-auto text-center transition ease-in-out duration-300 absolute right-0 mt-2 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">
                                        <div class="flex items-center text-gray-700 hover:text-teal-400 w-full">
                                            <font-awesome-icon icon="fa-duotone fa-address-card" />
                                            <router-link :to="{ name: 'profile' }" @click="showProfile = false" class="block pl-3 py-2 text-sm text-gray-700 w-full text-left hover:text-teal-400">Profile</router-link>
                                        </div>
                        
                                        <div v-if="mainStore.role != 'Instructor' && mainStore.isUserInstructor" class="flex items-center text-gray-700 hover:text-teal-400 w-full">
                                            <font-awesome-icon icon="fa-duotone fa-chalkboard-user" />
                                            <router-link :to="{
                name: 'organization',
                params: { id: mainStore.organization.companyID },
              }" @click="showProfile = false, mainStore.isLoadingInstructor = true" class="block pl-3 py-2 text-sm text-gray-700 w-full text-left hover:text-teal-400">Switch to Instructor</router-link>
                                        </div>
                                        <div class="flex items-center text-gray-700 hover:text-teal-400">
                                            <font-awesome-icon icon="fa-duotone fa-arrow-right-from-bracket" />
                                            <a @click.prevent="signOut" href="#" class="block pl-3 py-2 text-sm hover:text-teal-400">Sign
                                                out</a>
                                        </div>
                                        <hr />
                                        <div class="flex items-center">
                                            <font-awesome-icon icon="fa-duotone fa-money-check-dollar" />
                                            <span class="block pl-3 py-2 text-sm text-gray-700">Credit Balance:</span>
                                            <span class="block pl-3 py-2 text-sm text-gray-700 font-bold" role="menuitem" tabindex="-1" id="user-menu-item-2">${{
                          mainStore.user.credit && mainStore.isLoggedIn
                          ? mainStore.user.credit.amount.toFixed(2)
                          : "0.00"
                        }}</span>
                                        </div>
                                    </div>
                                </transition>

                            </div>
                        </div>
                    </div>

                    <router-link v-if="!mainStore.isLoggedIn" :to="{ name: 'login' }" type="button" class="inline-flex items-center border border-black border-solid bg-white py-2 px-3.5 text-base font-medium text-gray-700 shadow-sm hover:bg-black hover:text-white">Log
                        In</router-link>
                </div>
            </div>
        </div>
        <div class="flex flex-wrap justify-center py-4 lg:hidden">
            <router-link v-if="mainStore.isLoggedIn" :to="{ name: 'dashboard' }" class="mr-4"><a class="inline-block font-bold hover:text-gray-900" href="#">
                    <font-awesome-icon icon="fa-regular fa-graduation-cap" class="mr-1" />
                    My Learning
                </a>
            </router-link>

            <router-link :to="{ name: 'explore' }" class="mr-4"><a class="inline-block font-bold hover:text-gray-900" href="#">
                    <font-awesome-icon icon="fa-regular fa-store" class="mr-1" />
                    Explore
                </a>
            </router-link>

        </div>
    </nav>
</header>
</template>

<script>
import {
    mapStores
} from "pinia";

import useMainStore from "@/stores/main";
import {
    ref
} from "vue";
import {
    directive
} from "vue3-click-away";

export default {
    name: "Header",
    data() {
        return {
            showMessages: false,
            showProfile: false,
            userHasCredits: false,
            showNotifications: false,
            showNBA: false,
        };
    },
    directives: {
        ClickAway: directive,
    },
    computed: {
        ...mapStores(useMainStore),
    },
    mounted() {
        if (this.mainStore.isLoggedIn && this.mainStore.user.credit) {
            this.mainStore.user.credit.amount = 0;
            this.userHasCredits = true;
            this.showProfile = false;
            this.getUserCredits();
            this.getShoppingCartItems();
        } else {
            this.userHasCredits = false;
        }

        this.showNBA = window.location.host == "jrnba.refreps.com";
    },
    methods: {
        getUserCredits() {
            this.axios
                .get(`${import.meta.env.VITE_BASE_LINK}/getUserCredits`)
                .then((response) => {
                    if (response.data.code == 200) {
                        this.mainStore.user.credit = response.data.credit;
                        this.mainStore.baseCredit = response.data.credit;
                    }
                });
        },
        
        signOut() {
            this.axios.get(`${import.meta.env.VITE_BASE_LINK}/logout`).then((response) => {
                if (response.data.code == 200) {
                    this.mainStore.isLoggedIn = false;
                    this.showProfile = false;
                    this.mainStore.hasClickedExploreOnce = false;
                    this.$router.push({
                        name: "explore",
                    });
                    this.mainStore.organization = {};
                    this.mainStore.orgData = {};
                    this.mainStore.socket.emit("offline", {
                        id: this.mainStore.user.id,
                    });
                    this.emitter.emit('signOut', {
                        didSignOut: true
                    });

                    this.mainStore.user = {};
                }
            });
        },
        onClickAway() {
            this.showProfile = false;
        },
        goToNotifications() {
            this.$router.push({
                name: "notifications",
            });
            this.showNotifications = false;
        },
    },
};
</script>

<style>
.slide-left-leave-active,
.slide-right-enter {
    opacity: 0;
    transform: translate(-2em, 0);
}

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
    opacity: 0;
    transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
    transition-duration: 0.5s;
    transition-property: height, opacity, transform;
    transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
    overflow: hidden;
}

.router-link-active {
    color: rgb(55 65 81, 1);
}

.router-link-active:hover {
    color: rgb(45 212 191, 1);
}

.slide-fade3-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade3-leave-active {
    transition: all 0.1s linear;
}

.slide-fade3-enter-from,
.slide-fade3-leave-to {
    transform: translateY(10px);
    opacity: 0;
}

.slide-fade3-leave-active {
    transition: all 0.1s linear;
}
</style>
