<template>
<div class="">
    <div class="max-w-[75rem] mx-auto">
        <div v-if="showNBA" id="bg-image"></div>
        <div v-if="mainStore.activeCartItems.length == 0" class="grid h-[75vh] place-items-center">
            <div class="w-full text-center mx-auto mt-12 px-12">
                <span class="w-full text-center text-gray-400 mx-auto text-[18px]">
                    <img src="https://api.refreps.com/images/no-cart.png" class="w-[100px] mx-auto mb-6" alt="" />
                    Your cart is empty
                </span>
            </div>
        </div>
        <main class="mx-auto py-8 px-4 sm:px-4 md:px-12 lg:px-0">
            <h1 v-if="mainStore.activeCartItems.length != 0" class="text-xl font-semibold text-gray-800 text-left">
                Your Cart
            </h1>

            <div class="mt-4 lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-12 xl:gap-x-16">
                <section v-if="mainStore.activeCartItems.length != 0" aria-labelledby="cart-heading" class="lg:col-span-7">
                    <h2 id="cart-heading" class="sr-only">Items in your shopping cart</h2>

                    <ul role="list" class="divide-y bg-white px-8 divide-gray-200 border-t border-b border-gray-200">
                        <li class="flex py-6 sm:py-10" v-for="item in mainStore.activeCartItems">
                            <div class="flex-shrink-0">
                                <img :src="`https://api.refreps.com/images/${item.id}.webp`" :alt="item.name" class="h-24 w-24 rounded-md object-cover object-center sm:h-48 sm:w-48" />
                            </div>

                            <div class="ml-4 flex flex-1 flex-col justify-between sm:ml-6">
                                <div class="relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0">
                                    <div>
                                        <div class="flex justify-between">
                                            <h3 class="text-sm">
                                                <span class="font-bold text-gray-700 text-xl">{{
                            item.name
                          }}</span>
                                            </h3>
                                        </div>
                                        <div class="mt-2 flex text-sm">
                                            <p class="text-gray-500">{{ item.author ?? 'RefReps' }}</p>
                                            <p class="ml-1 border-l border-gray-200 pl-2 text-gray-500">
                                                {{ item.sport }}
                                            </p>
                                        </div>
                                        <p class="mt-1 text-lg font-medium text-gray-900">
                                            ${{ item.unitPrice.toFixed(2) }}
                                        </p>
                                    </div>

                                    <div class="mt-4 sm:mt-0 sm:pr-9">
                                        <div class="absolute top-0 right-0">
                                            <button @click="removeCourse(item.id)" type="button" class="-m-2 inline-flex p-2 text-gray-400 hover:text-gray-500">
                                                <span class="sr-only">Remove</span>

                                                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                    <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </section>

                <div v-if="mainStore.activeCartItems.length != 0" class="px-4 lg:col-span-5 lg:mt-0">
                    <div v-if="this.mainStore.showCreditBanner" class="shadow-md px-8 py-6 mb-4 bg-gray-50 text-black">
                        <h3 class="font-semibold uppercase text-xl">
                            RefReps<span class="text-teal-400">
                                <font-awesome-icon icon="fa-regular fa-trophy-star" /></span>Rewards
                        </h3>
                        <hr />
                        <!-- <span class="text-gray-400">Rewards Number: RR-80923489</span> -->
                        <div class="flex justify-between items-center mt-4">
                            <div class="relative flex items-center">
                                <div class="flex h-5 items-center">
                                    <input id="comments" aria-describedby="comments-description" v-model="mainStore.hasAppliedCredits" @input="toggleHasAppliedCredits" name="comments" type="checkbox" class="h-4 w-4 border-gray-300 text-teal-400 focus:ring-0 focus:ring-transparent" />
                                </div>
                                <div class="ml-1.5 text-sm">
                                    <label for="comments" class="font-bold text-gray-700 text-lg">${{ mainStore.initialCredit }} Reward</label>
                                    <br />
                                    <label v-if="mainStore.hasAppliedCredits" for="comments" class="text-gray-500 text-sm">${{ mainStore.initialCredit - appliedCredit.toFixed(2) }} remaining
                                        after purchase</label>
                                </div>
                            </div>

                            <span class="font-bold">Exp:
                                {{
                    mainStore.isLoggedIn
                      ? mainStore.user.credit.expires
                        ? `${formatUnixTime(mainStore.user.credit.expireDate)}`
                        : "Never"
                      : ""
                  }}</span>
                        </div>
                    </div>

                    <section aria-labelledby="summary-heading" class="mt-16 rounded-lg bg-gray-50 px-4 sm:p-6 lg:col-span-5 lg:mt-0 lg:p-8">
                        <h2 id="summary-heading" class="text-lg font-medium text-gray-900">
                            Order summary
                        </h2>

                        <dl class="mt-6 space-y-4">
                            <div class="flex items-center justify-between">
                                <dt class="text-sm text-gray-600">Subtotal</dt>
                                <dd class="text-sm font-medium text-gray-900">
                                    ${{ subTotal.toFixed(2) }}
                                </dd>
                            </div>
                            <div class="flex items-center justify-between border-t border-gray-200 pt-4">
                                <dt class="flex items-center text-sm text-gray-600">
                                    <span>Discounts</span>
                                </dt>
                                <dd class="text-sm font-medium" :class="
                      mainStore.hasAppliedDiscount ? 'text-teal-500' : 'text-gray-900'
                    ">
                                    {{
                      mainStore.hasAppliedDiscount
                        ? `-${appliedCodeDiscount.toFixed(2)}`
                        : `$0.00`
                    }}
                                </dd>
                            </div>
                            <div v-if="mainStore.hasAppliedCredits" class="flex items-center justify-between border-t border-gray-200 pt-4">
                                <dt class="text-sm text-gray-600">RefReps Rewards</dt>
                                <dd class="text-sm font-medium" :class="
                      mainStore.hasAppliedCredits ? 'text-teal-500' : 'text-gray-900'
                    ">
                                    -${{ appliedCredit.toFixed(2) }}
                                </dd>
                            </div>
                            <div class="flex items-center justify-between border-t border-gray-200 pt-4">
                                <dt class="text-base font-medium text-gray-900">Order total</dt>
                                <dd class="text-base font-medium text-gray-900">
                                    ${{ computedTotal.toFixed(2) }}
                                </dd>
                            </div>
                        </dl>

                        <div class="mt-6">
                            <div>
                                <p class="text-gray-400">Have a coupon code?</p>
                                <div class="flex justify-end" v-if="mainStore.hasAppliedDiscount">
                                    <div></div>
                                    <span class="inline-flex items-center py-0.5 pl-2.5 pr-1 text-sm font-medium text-gray-700 mb-4">
                                        {{ mainStore.discountCode }}
                                        <button @click="removeDiscount" class="ml-1.5 inline-flex h-3 w-3 flex-shrink-0 items-center justify-center rounded-full text-gray-400">
                                            <svg class="h-4 w-4" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                                                <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                                            </svg>
                                        </button>
                                    </span>
                                </div>
                                <div class="flex items-center justify-end">
                                    <input type="text" v-model="mainStore.discountCode" class="w-full focus:border-black" @input="showError = false" />
                                    <button @click="applyDiscount" class="inline p-2.5 w-16 text-white text-sm bg-black border border-black border-solid font-bold">
                                        Apply
                                    </button>
                                </div>
                                <p v-if="showError" class="text-red-600 mt-1 block text-left w-72">
                                    {{ errorMessage }}
                                </p>
                            </div>
                        </div>

                        <div class="mt-6">
                            <button @click="pay()" type="button" class="w-full border border-black border-solid bg-black py-3 px-4 text-base font-medium text-white">
                                <div class="inline" :class="{ hidden: !isLoading }">
                                    <i class="fas fa-circle-notch fa-spin mr-2 text-lg"></i>
                                </div>
                                <span class="inline">{{
                    computedTotal > 0 ? "Checkout" : "Purchase"
                  }}</span>
                            </button>
                        </div>
                    </section>
                </div>
            </div>
        </main>
    </div>

    <div class="relative" style="z-index: 99999" v-if="showNeedsLogin" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div class="fixed inset-0 z-10 overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div class="relative transform overflow-hidden bg-white px-4 pb-4 text-left transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                    <div>
                        <div class="text-left">
                            <h3 class="text-xl font-bold leading-6 text-gray-900" id="modal-title">
                                Account Required
                            </h3>
                            <div class="mt-1">
                                <p class="text-sm text-gray-500">
                                    You need an account to purchase this course.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5 sm:mt-6 flex items-center justify-between">
                        <button @click="showNeedsLogin = false" type="button" class="mr-4 inline-flex w-full justify-center border border-solid border-black px-4 py-4 text-base font-medium text-black hover:bg-black hover:text-white sm:col-start-2 sm:text-sm">
                            Not Now
                        </button>
                        <router-link :to="{ name: 'login' }" type="button" class="inline-flex w-full justify-center border border-solid border-black px-4 py-4 text-base font-medium text-black hover:bg-black hover:text-white sm:col-start-2 sm:text-sm">Login</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapStores,
    mapActions
} from "pinia";
import useMainStore from "@/stores/main";

export default {
    name: "CheckoutCart",
    computed: {
        ...mapStores(useMainStore),
        totalTaxes() {
            return (
                this.sumAll(this.mainStore.activeCartItems.map((item) => item.unitPrice)) *
                this.taxes
            );
        },
        subTotal() {
            return this.sumAll(this.mainStore.activeCartItems.map((item) => item.unitPrice));
        },
        totalDiscount() {
            return this.appliedCodeDiscount;
        },
        computedTotal() {
            return this.subTotal - this.appliedCredit;
        },
    },
    data() {
        return {
            taxes: 0,
            discountAmount: 0,
            unitPrice: 50.0,
            interactivePrice: 25.0,
            errorMessage: "",
            showError: false,
            hasDiscount: false,
            total: 0,
            discounts: 0,
            appliedCodeDiscount: 0,
            appliedCredit: 0,
            codeIsValid: false,
            showNeedsLogin: false,
            showCreditBanner: false,
            isLoading: false,
            creditRemaining: 0,
            toggleCredit: false,
            codeType: "",
            showNBA: false,
        };
    },
    mounted() {
        this.axios({
            method: "POST",
            url: `${import.meta.env.VITE_BASE_LINK}/authenticateUser`,
            data: {
                apiKey: "EjuoZZcumh1SQwCX1LuiLDbpusDue2k64CtVXcgLjXFDTMxPvif1498TPHWXcGW9",
            },
        }).then((response) => {
            if (response.data.isLoggedIn) {
                this.mainStore.user = response.data.user;
                this.mainStore.showCreditBanner = response.data.user.credit.amount > 0;
                this.mainStore.user.credit.amount = response.data.user.credit.amount;
                this.mainStore.initialCredit = response.data.user.credit.amount;
                console.log(this.mainStore.showCreditBanner);
            }
        });

        this.showNBA = window.location.host == "jrnba.refreps.com";

        if (!this.mainStore.hasAppliedDiscount) {
            this.removeDiscount();
        }
        this.isLoading = false;
        this.mainStore.hasAppliedCredits = false;
        this.mainStore.currentPage = this.$route.name;
        this.total = 0;
        this.appliedCodeDiscount = 0;
        if (this.mainStore.isLoggedIn && this.mainStore.user.credit) {
            this.mainStore.showCreditBanner = this.mainStore.initialCredit > 0;
        }
        if (this.mainStore.hasAppliedDiscount || this.mainStore.hasAppliedCredits) {
            this.mainStore.activeCartItems.forEach((item) => {
                this.appliedCodeDiscount +=
                    item.productType == "course" ?
                    this.mainStore.baseCourseUnitPrice * this.mainStore.discountAmount :
                    this.mainStore.baseInteractiveUnitPrice * this.mainStore.discountAmount;
            });
        }

    },
    methods: {
        ...mapActions(useMainStore, ["updateRemoteCart"]),
        toggleHasAppliedCredits() {
            this.mainStore.hasAppliedCredits = !this.mainStore.hasAppliedCredits;
            if (this.mainStore.hasAppliedCredits) {
                this.applyCredits();
            } else {
                // this.mainStore.user.credit.amount = this.mainStore.baseCredit.amount;
                const creditsRemaining =
                    this.subTotal + this.totalTaxes > this.mainStore.user.credit.amount ?
                    this.subTotal + this.totalTaxes - this.mainStore.user.credit.amount :
                    this.mainStore.user.credit.amount - this.subTotal + this.totalTaxes;
                const totalDiscounted =
                    this.subTotal + this.totalTaxes > this.mainStore.user.credit.amount ?
                    this.mainStore.user.credit.amount :
                    this.subTotal + this.totalTaxes;
                this.appliedCredit = 0;
                this.discounts = totalDiscounted;
                this.creditRemaining = creditsRemaining;
                this.mainStore.user.credit.amount =
                    this.mainStore.baseCredit.amount + this.subTotal;
            }
        },
        sumAll(items) {
            var i;
            var sum = 0;
            for (i = 0; i < items.length; i++) {
                sum += items[i];
            }
            return sum;
        },
        applyCredits() {
            const creditsRemaining =
                this.subTotal >= this.mainStore.user.credit.amount ?
                this.subTotal - this.mainStore.user.credit.amount :
                this.mainStore.user.credit.amount - this.subTotal;
            const totalDiscounted =
                this.subTotal >= this.mainStore.user.credit.amount ?
                this.mainStore.user.credit.amount :
                this.subTotal + this.totalTaxes;

            this.creditRemaining = creditsRemaining;
            this.computedTotal = this.computedTotal - totalDiscounted;
            this.appliedCredit = totalDiscounted;
            this.mainStore.user.credit.amount =
                creditsRemaining > this.total + this.totalTaxes ? creditsRemaining : 0;
            this.discounts = totalDiscounted;
            this.mainStore.hasAppliedCredits = true;
        },
        addToTotal(price) {
            this.total += price;
        },
        removeCourse(id) {
            this.mainStore.activeCartItems = this.mainStore.activeCartItems.filter(
                (course) => course.id !== id
            );
            this.mainStore.updateRemoteCart();
            if (this.mainStore.activeCartItems.length == 0) {
                this.mainStore.discountCode = "";
                this.appliedCodeDiscount = 0;
                this.mainStore.codeIsValid = false;
                this.mainStore.hasAppliedCredits = true;
                this.mainStore.hasAppliedDiscount = false;
            }
        },
        removeDiscount() {
            this.mainStore.hasAppliedDiscount = false;
            this.appliedCodeDiscount = 0;
            this.mainStore.activeCartItems.forEach((item) => {
                item.unitPrice =
                    item.productType == "course" ?
                    this.mainStore.baseCourseUnitPrice :
                    this.mainStore.baseInteractiveUnitPrice;
            });
            this.mainStore.updateRemoteCart();
            this.mainStore.codeType = "";
            this.mainStore.discountCode = "";
            this.discounts = 0;
            this.mainStore.codeIsValid = false;
        },
        continueShopping() {
            this.$router.push({
                name: "explore",
            });
        },
        applyDiscount() {
            if (this.mainStore.hasAppliedDiscount) {
                return;
            }
            this.axios
                .post(`${import.meta.env.VITE_BASE_LINK}/check-code`, {
                    code: this.mainStore.discountCode,
                })
                .then((response) => {
                    if (response.data.isValid) {
                        console.log(response.data);
                        this.mainStore.codeIsValid = true;
                        this.showError = false;
                        this.errorMessage = "";
                        this.mainStore.hasAppliedDiscount = true;
                        this.total = 0;
                        this.mainStore.codeType = response.data.code.type;
                        this.mainStore.activeCartItems.forEach((item) => {
                            item.unitPrice =
                                item.unitPrice - item.unitPrice * response.data.code.discount;
                            this.appliedCodeDiscount +=
                                item.productType == "course" ?
                                this.mainStore.baseCourseUnitPrice * this.mainStore.discountAmount :
                                this.mainStore.baseInteractiveUnitPrice *
                                this.mainStore.discountAmount;
                        });
                        this.mainStore.courses.forEach((item) => {
                            item.unitPrice =
                                item.unitPrice - item.unitPrice * response.data.code.discount;
                        });
                        console.log(this.appliedCodeDiscount);
                        this.mainStore.updateRemoteCart();
                        this.mainStore.discountAmount = response.data.code.discount;
                    } else {
                        this.errorMessage = "The code was invalid or expired.";
                        this.showError = true;
                        this.total = 0;
                        this.mainStore.codeType = "";
                        this.discounts = 0;
                        this.mainStore.discountCode = "";
                        this.mainStore.codeIsValid = false;
                        this.mainStore.hasAppliedDiscount = false;
                        this.mainStore.activeCartItems.forEach((item) => {
                            item.unitPrice =
                                item.productType == "course" ?
                                this.mainStore.baseCourseUnitPrice :
                                this.mainStore.baseInteractiveUnitPrice;
                        });
                        this.mainStore.courses.forEach((item) => {
                            item.unitPrice =
                                item.productType == "course" ?
                                this.mainStore.baseCourseUnitPrice :
                                this.mainStore.baseInteractiveUnitPrice;
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        pay() {
            if (!this.mainStore.isLoggedIn) {
                return (this.showNeedsLogin = true);
            }

            this.mainStore.isLoadingInstructor = true;
            // var audio = new Audio('https://api.refreps.com/woo.mp3')
            // audio.play()
            this.isLoading = true;
            // setTimeout(() => {

            var direction = this.computedTotal > 0;
            var mode =
                import.meta.env.VITE_MODE;
            this.axios
                .post(
                    direction ?
                    mode == "production" ?
                    `${import.meta.env.VITE_STRIPE_LINK}/create-checkout-session` :
                    `${import.meta.env.VITE_STRIPE_LINK}/create-checkout-session-dev` :
                    `${import.meta.env.VITE_STRIPE_LINK}/purchase`, {
                        items: JSON.stringify(
                            this.mainStore.activeCartItems.map((item) => {
                                return {
                                    id: item.id,
                                    name: item.name,
                                    price: item.unitPrice * 100,
                                };
                            })
                        ),
                        ids: this.mainStore.activeCartItems.map((item) => {
                            return {
                                id: item.id,
                                type: item.productType,
                            };
                        }),
                        creditUsed: this.appliedCredit,
                        amount_off: this.appliedCredit * 100,
                        code: this.mainStore.codeIsValid ? this.mainStore.discountCode : "",
                        codeType: this.mainStore.codeType,
                        id: this.mainStore.user.id,
                        email: this.mainStore.user.email,
                        name: this.mainStore.user.name,
                        profileImage: this.mainStore.user.profileImage,
                        customer: this.mainStore.user.customerID,
                    }
                )
                .then((response) => {
                    if (response.data.code == 200) {
                        this.mainStore.isLoadingInstructor = false;
                        this.mainStore.user.credit.amount = response.data.credits;
                        window.location.href = response.data.url;
                    } else {
                        this.isLoading = false;
                        this.mainStore.isLoadingInstructor = false;
                        alert("There was an error purchasing courses.");
                    }
                })
                .catch((error) => {
                    alert(error);
                    this.isLoading = false;
                    this.mainStore.isLoadingInstructor = false;
                    console.log(error);
                });

            // }, 1000);
        },
        formatUnixTime(unix_time) {
            const milliseconds = unix_time * 1000; // 1575909015000

            const dateObject = new Date(milliseconds);

            const humanDateFormat = dateObject.toLocaleString(); //2019-12-9 10:30:15

            const weekDay = dateObject.toLocaleString("en-US", {
                weekday: "long",
            }); // Monday
            const month = dateObject.toLocaleString("en-US", {
                month: "short",
            }); // December
            const day = dateObject.toLocaleString("en-US", {
                day: "numeric",
            }); // 9
            const year = dateObject.toLocaleString("en-US", {
                year: "numeric",
            }); // 2019

            return `${month} ${day}, ${year}`;
        },
    },
};
</script>

<style>
#bg-image::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    z-index: -1;
    opacity: 40%;
    overflow: hidden;
    background: url("https://api.refreps.com/images/white.png");
    transform: rotate(-10deg);
}
</style>
