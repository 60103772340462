<template>
    <div>
        <div class="relative" style="z-index: 999999;" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    
        
                <div class="fixed inset-0 bg-gray-700 bg-opacity-40 transition-opacity animate__animated animate__fadeIn" style="--animate-duration: 0.4s"></div>
    
                <div class="fixed inset-0 z-10 overflow-y-auto">
                    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
    
                        <section class="rounded-3xl shadow-2xl bg-white max-w-sm animate__animated animate__fadeInDown" style="--animate-duration: 0.2s">
                            <div class="p-8 text-center sm:p-12">
                                <p class="text-sm font-semibold uppercase tracking-widest text-gray-400">
                                    RefReps
                                </p>
    
                                <h2 class="mt-6 text-3xl font-bold">
                                    No refunds homie! You bought it, you keep it.
                                </h2>
    
                                <button @click="mainStore.showNoRefundModal = false" class="mt-8 inline-block w-full rounded-full bg-black py-4 text-sm font-bold text-white shadow-xl" href="">
                                    Back to Cart
                                </button>
                                <button @click="continueToPayment()" class="mt-8 inline-block w-full rounded-full bg-black py-4 text-sm font-bold text-white shadow-xl" href="">
                                    Continue to Checkout
                                </button>
                            </div>
                        </section>
    
                    </div>
    
                </div>
    
    
        </div>
    </div>
    </template>
    
    <script>
    
    import {
        mapStores,
        mapActions
    } from "pinia";
    import useMainStore from "@/stores/main";
    
    export default {
        name: 'NoRefundModal',
        data() {
            return {
    
            }
        },
        methods: {
            continueToPayment() {
                this.mainStore.showNoRefundModal = false;
                this.mainStore.isLoadingInstructor = true;
                this.emitter('continueToPayment');
            }
        },
        computed: {
            ...mapStores(useMainStore),
        },
        mounted() {
    
        }
    }
    </script>
    