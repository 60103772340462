<template>
<div class="relative z-[9999]" role="dialog" aria-modal="true">

    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity animate__animated animate__fadeIn"></div>

    <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
            <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">

                <div class="pointer-events-auto relative w-[500px]">

                    <div class="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                        <button @click="mainStore.showMultiAssign = false" type="button" class="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                            <span class="absolute -inset-2.5"></span>
                            <span class="sr-only">Close panel</span>
                            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>

                    <!-- Slide-over panel, show/hide based on slide-over state. -->
                    <div class="h-full overflow-y-auto bg-white p-4 animate__animated animate__fadeInRight">
                        <div class="space-y-6 pb-16">
                            <div class="relative flex flex-col items-center rounded-[20px] mx-auto p-4 bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:!shadow-none">

                                <div v-if="selectedCourse">
                                    <div class="relative flex h-32 w-full justify-center rounded-xl bg-cover">
                                        <img src='https://horizon-tailwind-react-git-tailwind-components-horizon-ui.vercel.app/static/media/banner.ef572d78f29b0fee0a09.png' class="absolute flex h-32 w-full justify-center rounded-xl bg-cover">
                                        <div class="relative bg-teal-400 h-32 w-full bg-opacity-[0.75] rounded-xl">

                                        </div>
                                        <div class="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-700">
                                            <img class="h-full w-full rounded-full object-cover" :src="`https://api.refreps.com/images/${selectedCourse.id}.webp`" alt="" />
                                        </div>
                                    </div>
                                    <div class="mt-16 flex flex-col items-center">
                                        <h4 class="text-xl font-bold text-navy-700 dark:text-white">
                                            {{ selectedCourse.name }}
                                        </h4>
                                        <p class="text-base font-normal text-gray-600">{{ selectedCourse.sport }}</p>
                                    </div>
                                    <!-- <div class="mt-6 mb-3 w-full text-center font-bold text-lg">
                                        Select students below to assign.
                                    </div> -->
                                </div>

                                <select @change="setCourse()" id="courses" v-model="selectedCourseID" class="bg-gray-50 border mb-4 border-gray-200 text-gray-900 text-sm rounded-lg block w-full p-2.5">
                                    <optgroup v-for="sport in uniq(mainStore.courses.map(c => c.sport))" :label="sport">
                                        <option v-for="course in mainStore.courses.filter(course => course.sport == sport)" :value="course.id">{{ course.name }}</option>
                                    </optgroup>
                                </select>

                                <div class="w-full mb-2">

                                    <div class="relative">
                                        <font-awesome-icon icon="fa-duotone fa-magnifying-glass" class="absolute text-gray-400 top-2.5 left-4" />
                                        <input v-model="searchText" type="text" class="bg-white border-1 border-gray-200 focus:ring-black focus:border-black w-full px-12 rounded-lg focus:outline-none" name="">
                                        <span @click="searchText = ''" class="absolute top-2 right-5 border-l pl-4">
                                            <font-awesome-icon icon="fa-solid fa-xmark" class="text-gray-500 hover:text-green-500 hover:cursor-pointer" />
                                        </span>
                                    </div>

                                    <div class="hidden sm:block">
                                        <div class="border-b border-gray-200">
                                            <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                                                <!-- Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700" -->
                                                <a @click.prevent="currentTab = 'assign'" href="#" :class="currentTab == 'assign' ? 'text-teal-400 border-b-2 border-teal-400' : 'border-transparent'" class="text-gray-500 hover:border-teal-600 hover:text-gray-700 flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium">
                                                    Assign
                                                    <!-- Current: "bg-indigo-100 text-indigo-600", Default: "bg-gray-100 text-gray-900" -->
                                                    <span class="bg-gray-100 text-gray-900 ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block">{{ filteredStudents.filter(s => !s.courseIds.includes(mainStore.currentCourseToAssign)).length }}</span>
                                                </a>
                                                <a @click.prevent="currentTab = 'unassign'" href="#" :class="currentTab == 'unassign' ? 'text-teal-400 border-b-2 border-teal-400' : 'border-transparent'" class="text-gray-500 hover:border-teal-600 hover:text-gray-700 flex whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium">
                                                    Unassign
                                                    <span class="bg-gray-100 text-gray-900 ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block">{{ filteredStudents.filter(s => s.courseIds.includes(mainStore.currentCourseToAssign)).length }}</span>
                                                </a>

                                            </nav>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="currentTab == 'assign'" v-for="student in filteredStudents.filter(s => !s.courseIds.includes(mainStore.currentCourseToAssign))" @click.prevent="pushPullCourse(student.info.id)" :class="mainStore.multiAssignStudents.includes(student.info.id) ? 'border-2 border-teal-400' : 'border-gray-300'" class="relative w-full my-2 flex items-start space-x-3 rounded-lg border bg-white px-4 py-3 shadow-sm hover:border-2 hover:border-teal-400">
                                    <div class="flex-shrink-0">
                                        <img class="h-10 w-10 rounded-full object-cover" :src="student.info.profileImage" alt="">
                                    </div>
                                    <div class="min-w-0 flex-1">
                                        <a href="#" class="focus:outline-none">
                                            <span class="absolute inset-0" aria-hidden="true"></span>
                                            <p class="text-sm font-medium text-gray-900">{{ student.info.name }}</p>
                                            <p class="truncate text-xs text-gray-500">{{ student.info.email }}</p>
                                        </a>

                                    </div>
                                    <font-awesome-icon :icon="mainStore.multiAssignStudents.includes(student.info.id) ? 'fa-solid fa-circle-check' : 'fa-regular fa-circle'" :class="mainStore.multiAssignStudents.includes(student.info.id) ? 'text-teal-400' : 'text-gray-200'" class="w-5 h-5" />
                                </div>

                                <div v-if="currentTab == 'unassign'" v-for="student in filteredStudents.filter(s => s.courseIds.includes(mainStore.currentCourseToAssign))" @click.prevent="pushPullCourse(student.info.id)" :class="mainStore.multiAssignStudents.includes(student.info.id) ? 'border-2 border-teal-400' : 'border-gray-300'" class="relative w-full my-2 flex items-start space-x-3 rounded-lg border bg-white px-4 py-3 shadow-sm hover:border-2 hover:border-teal-400">
                                    <div class="flex-shrink-0">
                                        <img class="h-10 w-10 rounded-full object-cover" :src="student.info.profileImage" alt="">
                                    </div>
                                    <div class="min-w-0 flex-1">
                                        <a href="#" class="focus:outline-none">
                                            <span class="absolute inset-0" aria-hidden="true"></span>
                                            <p class="text-sm font-medium text-gray-900">{{ student.info.name }}</p>
                                            <p class="truncate text-xs text-gray-500">{{ student.info.email }}</p>
                                        </a>

                                    </div>
                                    <font-awesome-icon :icon="mainStore.multiAssignStudents.includes(student.info.id) ? 'fa-solid fa-circle-check' : 'fa-regular fa-circle'" :class="mainStore.multiAssignStudents.includes(student.info.id) ? 'text-teal-400' : 'text-gray-200'" class="w-5 h-5" />
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapStores
} from 'pinia'
import useMainStore from '@/stores/main'
export default {
    data() {
        return {
            selectedCourse: null,
            courses: [],
            currentTab: 'assign',
            currentCourse: null,
            searchText: '',
            selectedCourseID: '',
            projectedSeats: 0,
            studentsToAssign: [],
        };
    },
    computed: {
        ...mapStores(useMainStore),
        filteredStudents() {

            if (this.searchText === '' || this.searchText === undefined) return this.mainStore.students;
            return this.mainStore.students.filter(student => {
                return student.info.name.toLowerCase().indexOf(this.searchText.toLowerCase()) != -1 || student.info.email.toLowerCase().indexOf(this.searchText.toLowerCase()) != -1
            })
        },
    },
    components: {

    },
    methods: {
        uniq(a) {
            return Array.from(new Set(a));
        },
        setCourse() {
            this.selectedCourse = this.mainStore.courses.find(course => course.id == this.selectedCourseID);
            this.studentsToAssign = [],
                this.projectedSeats = this.mainStore.organization.seats - this.studentsToAssign.length
        },
        pushPullCourse(id) {

            if (this.mainStore.multiAssignStudents.includes(id)) {
                this.mainStore.multiAssignStudents = this.mainStore.multiAssignStudents.filter(i => i != id)
                this.projectedSeats = this.mainStore.organization.seats - this.mainStore.multiAssignStudents.length
            } else {
                if (this.projectedSeats == 0) return alert('You have no more seats available.')
                this.mainStore.multiAssignStudents.push(id)
                this.projectedSeats = this.mainStore.organization.seats - this.mainStore.multiAssignStudents.length
            }
        },
    },
    mounted() {
        this.selectedCourse = this.mainStore.courses[0];
        this.selectedCourseID = this.selectedCourse.id;
        this.projectedSeats = this.mainStore.organization.seats
    }
}
</script>
