<template>
<div class="py-2 animate__animated animate__fadeInLeft" :class="mainStore.isDragging ? 'blur-sm' : ''">

    <button @click="goBack()" class="inline-flex text-lg items-center justify-center w-full text-black sm:w-auto sm:mb-0">
        <font-awesome-icon icon="fa-solid fa-arrow-left" class="mr-2" />
        Back
    </button>

    <h2 class="text-2xl font-bold text-black mt-3">
        <!-- <font-awesome-icon icon="fa-solid fa-bug" class="h-8 w-8 mr-2" /> -->
        <span>Support Ticket</span>
    </h2>
    <p class="text-[16px] font-regular text-gray-500 mb-4">Fill out the information below. Please give as much detail as you can about the issue you are having.</p>
    <!-- <a href="https://support.refreps.com" target="_blank" class="text-teal-400 pb-4">Help Center</a> -->

    <vee-form :validation-schema="schema" @submit="submitTicket" class="mt-1 max-w-4xl mx-auto" @drop="onDrop($event, 1)" @mouseover="mainStore.hover = true" @mouseleave="mainStore.hover = false" @dragover.prevent @dragenter.prevent>
        <div class="grid grid-cols-1 gap-6">

            <div class="grid grid-cols-2 gap-4">

                <label class="block">
                    <span class="text-black font-bold">* Course Affected</span>
                    <vee-field v-model="mainStore.user_course.name" as="select" name="course" class="block w-full mt-1">
                        <option value="">...</option>
                        <option value="All Courses">All Courses</option>
                        <option value="No course affected">No course affected</option>
                        <option v-for="course in mainStore.userCourses" :value="course.name">{{ course.name }}</option>
                    </vee-field>
                    <ErrorMessage class="text-red-600" name="course" />
                </label>

                <label class="block">
                    <span class="text-black font-bold">* Issue Type</span>
                    <vee-field v-model="issueType" as="select" name="issueType" class="block w-full mt-1">
                        <option value="">...</option>
                        <option value="Stuck in course/video training pack">Stuck in course/video training pack</option>
                        <option value="Login Issues">Login Issues</option>
                        <option value="Change/Reset Password">Change/Reset Password</option>
                        <option value="Adding Students">Adding Students</option>
                        <option value="Assigning Students">Assigning Students</option>
                        <option value="Deleting/Archiving Students">Deleting/Archiving Students</option>
                        <option value="Teach RefReps Officiating Course(s)">Teach RefReps Officiating Course(s)</option>
                        <option value="Other">Other</option>

                    </vee-field>
                    <ErrorMessage class="text-red-600" name="issueType" />
                </label>

            </div>

            <label v-if="affectedStudents.length != 0" class="block">
                <span class="text-black font-bold">Affected Students</span>
                <br>
                <p v-for="student in affectedStudents" target="_blank" class="bg-gray-100 text-gray-800 animate__animated animate__zoomIn text-xs font-medium inline-flex items-center px-2.5 py-0.5 my-1 rounded mr-2 border border-gray-300">
                    <font-awesome-icon icon="fa-regular fa-user" class="mr-2" />
                    {{ student }}
                </p>
            </label>

            <label class="block">
                <span class="text-black font-bold">* {{ issueType == 'Other' ? 'Please Specify Your Issue' : 'Description' }}</span>
                <br>

                <a v-for="suggestion in suggestions" :href="suggestion.url" target="_blank" class="bg-gray-100 text-gray-800 animate__animated animate__zoomIn text-xs font-medium inline-flex items-center px-2.5 py-0.5 my-1 rounded mr-2 border border-gray-300">
                    <font-awesome-icon icon="fa-solid fa-link" class="mr-2" />
                    {{ suggestion.name }}
                </a>

                <vee-field as="textarea" @input="checkForKeyWords()" v-model="description" name="description" id="editing" class="mt-1 block w-full h-24" placeholder="Enter description here..." />

                <p class="mt-2" :class="!descriptionIsValid ? 'text-gray-800' : 'text-green-600'">
                    <font-awesome-icon v-if="descriptionIsValid" icon="fa-solid fa-check" class="mr-2" />{{ !descriptionIsValid ? `${15 - description.split('').length} characters remaining to be valid.` : 'Description minimum met!' }}</p>
                <ErrorMessage class="text-red-600" name="description" />
            </label>

            <label class="block">
                <span class="text-black font-bold mb-2">Add Screen Shot</span>
                <p class="text-[16px] font-regular text-gray-500 mb-4">Screen shots are very helpful in debugging errors.</p>
                <button v-tooltip="{ content: `Take Screen Shot`, html: true }" v-if="screenShots.length == 0" @click="capture()" type="button" class="text-black inline-flex bg-gray-100 hover:bg-gray-200 font-medium rounded-lg text-sm px-4 py-4 text-center items-center">
                    <font-awesome-icon icon="fa-regular fa-camera-viewfinder" class="w-5 h-5" />
                </button>
            </label>

            <div v-if="screenShots.length != 0" class="w-full grid grid-cols-3">
                <font-awesome-icon v-if="isUploadingScreenShot" icon="fa-duotone fa-spinner-third" class="h-6 w-6" spin />
                <div v-for="screenShot in screenShots" class="relative max-w-[150px] h-32">
                    <button @click.prevent="deleteImage(screenShot.id)" class="absolute top-0 right-0 text-red-500 z-[99999]">
                        <font-awesome-icon v-if="!isUploadingScreenShot" icon="fa-solid fa-circle-minus" class="h-6 w-6" />
                    </button>

                    <img :src="screenShot.img" v-show="!isUploadingScreenShot" class="shadow-2xl max-w-[150px] min-w-[150px] h-24 mt-2 object-cover" />
                </div>
            </div>

        </div>
        <div class="flex items-center justify-center w-full">
            <button class="mt-8 inline-flex items-center justify-center w-full bg-black py-4 text-sm text-center font-bold text-white shadow-xl">
                <span v-if="!isSubmittingTicket" class="text-lg">Submit Ticket</span>
                <font-awesome-icon v-if="isSubmittingTicket" icon="fa-duotone fa-spinner-third" class="w-6 h-6 ml-2 text-white" spin />
            </button>

        </div>

       
    </vee-form>

</div>

<div v-if="isUploadingScreenShot" class="fixed top-0 left-0 right-0 bottom-0 w-full h-full z-[99999] overflow-hidden bg-white bg-opacity-70 flex flex-col items-center justify-center">
    <div class="grid place-items-center">
        <div aria-label="Loading..." role="status" class="text-center">
            <font-awesome-icon icon="fa-duotone fa-spinner-third" class="w-12 h-12" spin />
        </div>
    </div>
</div>
</template>

<script>
import {
    mapStores,
    mapActions
} from "pinia";
import useMainStore from "@/stores/main";
import html2canvas from "html2canvas";
import {
    uuid
} from "vue-uuid";

export default {
    data() {
        return {
            subject: '',
            description: '',
            schema: {
                issueType: "required",
                description: "required|min:15",
                course: "required",
            },
            isSubmittingTicket: false,
            isUploadingScreenShot: false,
            showScreenShot: false,
            refrepsTicketID: '',
            hasImage: false,
            files: [],
            issueType: '',
            descriptionCount: 0,
            data: '',
            editor: null,
            options: {
                modules: {
                    toolbar: [],
                },
            },
            suggestions: [],
            affectedStudents: [],
            screenShots: [],
            isDragOver: false,
            hover: false,
        }
    },
    computed: {
        ...mapStores(useMainStore),
        descriptionIsValid() {
            return this.description.length >= 15;
        },

    },
    methods: {
        checkForKeyWords() {

            if (this.mainStore.role == 'Instructor') {
                if (this.description.toLowerCase().split(' ').filter(word => this.mainStore.orgStudents.includes(word))) {
                    var students = this.description.toLowerCase().split(' ').filter(word => this.mainStore.orgStudents.includes(word))
                    this.affectedStudents = students
                } else {
                    this.affectedStudents = []
                }
            }

            if (this.description.toLowerCase().split(' ').includes("progress")) {
                if (!this.suggestions.map(suggestion => suggestion.name).includes("Progression Issue")) {
                    this.suggestions.push({
                        name: "Progression Issue",
                        url: "https://support.refreps.com/support/solutions/articles/151000082592-progression-issue"
                    })
                }
            } else {
                this.suggestions = this.suggestions.filter(suggestion => suggestion.name != "Progression Issue")
            }
            if (this.description.toLowerCase().split(' ').includes("login")) {
                if (!this.suggestions.map(suggestion => suggestion.name).includes("How to Login")) {
                    this.suggestions.push({
                        name: "How to Login",
                        url: "https://support.refreps.com/support/solutions/articles/44002178377-how-to-login"
                    })
                }
            } else {
                this.suggestions = this.suggestions.filter(suggestion => suggestion.name != "How to Login")
            }

            if (this.description.toLowerCase().split(' ').includes("password")) {
                if (!this.suggestions.map(suggestion => suggestion.name).includes("How to Reset/Create your Password")) {
                    this.suggestions.push({
                        name: "How to Reset/Create your Password",
                        url: "https://support.refreps.com/support/solutions/articles/151000082574-how-to-login"
                    })
                }
            } else {
                this.suggestions = this.suggestions.filter(suggestion => suggestion.name != "How to Reset/Create your Password")
            }

            if (this.description.toLowerCase().split(' ').includes("cache")) {
                if (!this.suggestions.map(suggestion => suggestion.name).includes("How to Clear Cache")) {
                    this.suggestions.push({
                        name: "How to Clear Cache",
                        url: "https://support.refreps.com/support/solutions/articles/151000098610-how-to-clear-cache"
                    })
                }
            } else {

                this.suggestions = this.suggestions.filter(suggestion => suggestion.name != "How to Clear Cache")
            }

        },
        submitTicket(values) {
            this.isSubmittingTicket = true;

            this.axios
                .post(`${import.meta.env.VITE_BASE_LINK}/submit-ticket`, {
                    ticket: {
                        role: this.mainStore.role,
                        email: this.mainStore.user.email,
                        issueType: this.issueType,
                        description: values.description,
                        course: this.mainStore.user_course.name,
                        refrepsTicketID: this.refrepsTicketID,
                        affectedUsers: this.affectedStudents,
                        hasImage: this.screenShots.length != 0,
                        files: this.files,
                        companyIDs: [...this.mainStore.user.organizations.map(org => org.id), ...this.$route.params.id ?? []],
                        logs: this.mainStore.logs,
                        errors: this.mainStore.errors,
                    },
                    data: this.mainStore.user_course
                })
                .then((response) => {
                    if (response.data.code == 200) {
                        this.isSubmittingTicket = false;
                        this.mainStore.ticketNumber = response.data.ticketID;
                        this.mainStore.showTicketConfirmation = true;
                        this.mainStore.showSubmitTicketModal = false
                        this.isGeneratingScreenShot = false;

                    } else {
                        alert('Error in submitting ticket. Please try again.');
                        this.isSubmittingTicket = false;
                    }
                })
                .catch((error) => {
                    alert(error);
                    console.log(error);
                    this.isSubmittingTicket = false;
                    this.isGeneratingScreenShot = false;
                });
        },
        capture() {

            this.isUploadingScreenShot = true;
            this.showScreenShot = true;
            this.isGeneratingScreenShot = true;
            html2canvas(document.body)
                .then(async canvas => {

                    var img = canvas.toDataURL({
                        format: 'png',
                        quality: 0.5
                    });
                    var file = this.dataURLtoFile(img, 'temp.png');
                    var id = uuid.v4();
                    this.uploadFile(file, img, id)

                });
        },
        async uploadFile(file, img, id) {

            console.log(id)

            const URL = `${import.meta.env.VITE_BASE_LINK}/upload-screen-shot`
            const data = new FormData();
            data.append("refrepsTicketID", id);
            data.append("file", file);
            const config = {
                header: {
                    "Content-Type": "image/jpg",
                },
            };

            await this.axios.put(URL, data, config)
                .then(response => {
                    if (response.data.success) {
                        this.isGeneratingScreenShot = false;
                        this.hasImage = true;
                        this.screenShots.push({
                            id: id,
                            img: img
                        })
                        var file = this.dataURLtoFile(img, 'temp.png');
                        this.files.push({
                            id: id,
                            file: file
                        })
                        this.isUploadingScreenShot = false;
                    } else {
                        this.isUploadingScreenShot = false;
                    }
                })
                .catch(error => {
                    alert(error)
                    this.hasImage = false;
                    this.isUploadingScreenShot = false;
                })

            // // Configure AWS S3
            // AWS.config.update({
            //     accessKeyId: 'AKIAVURIASHPOMW46WOX',
            //     secretAccessKey: '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',
            //     region: 'us-east-2',
            // });

            // const s3 = new AWS.S3();
            // const params = {
            //     Bucket: 'refprep',
            //     Key: 'support/' + this.refrepsTicketID + '.png',
            //     Body: file,
            // };

            // try {
            //     const upload = s3.upload(params);

            //     // Update progress
            //     upload.on("httpUploadProgress", (evt) => {
            //         // this.uploadPercentage = Math.round((evt.loaded / evt.total) * 100);
            //     });

            //     await upload.promise();
            //     this.isGeneratingScreenShot = false;
            //     document.getElementById("screenShot").src = img;

            // } catch (error) {
            //     alert("Upload failed:", error);
            //     this.isGeneratingScreenShot = false;
            // }
        },
        dataURLtoFile(dataurl, filename) {

            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }

            return new File([u8arr], filename, {
                type: mime
            });
        },
        deleteImage(id) {

            this.isUploadingScreenShot = true
            this.axios
                .post(`${import.meta.env.VITE_BASE_LINK}/delete-screen-shot`, {
                    refrepsTicketID: id,
                })
                .then((response) => {

                    if (response.data.code == 200) {
                        this.screenShots = this.screenShots.filter((shot) => shot.id != id);
                        this.isUploadingScreenShot = false
                    } else {
                        console.log('Error removing screenshot.');
                        this.isUploadingScreenShot = false
                    }
                })
                .catch((error) => {
                    console(error);
                    this.isUploadingScreenShot = false
                });

        },
        generateString() {
            const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789';
            let result = '';

            for (let i = 0; i < 10; i++) {
                const randomIndex = Math.floor(Math.random() * chars.length);
                result += chars[randomIndex];
            }

            return result;
        },
        onDrop(evt, list) {
            const id = evt.dataTransfer.getData('id')
            const img = evt.dataTransfer.getData('img')
            var file = this.dataURLtoFile(img, 'temp.png');
            this.uploadFile(file, img, id)
            this.mainStore.screenShots = this.mainStore.screenShots.filter((shot) => shot.id != id);
            this.isUploadingScreenShot = true;
        },
        dragEnter() {
            this.isDragOver = true;
        },
        dragLeave() {
            // this.isDragOver = false;
            console.log('Leaving')
        },
        uniq(a) {
            return Array.from(new Set(a));
        },
        goBack() {
            this.screenShots.forEach((shot) => {
                this.deleteImage(shot.id)
            })
            this.mainStore.currentSupportView = 'TopIssues'
            this.isSubmittingTicket = false
        }

    },
    mounted() {
        this.refrepsTicketID = this.generateString();
        this.editor = this.$refs.editor
    },

}
</script>

<style scoped>
</style>
